import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from './types'
import { Device } from '../../models'
import api from '../../api'
import config from '../../config'
import { Modal } from '../ui'
import { Form } from './form'
import { useAppState } from '../app-state'


// THIS DOOR SHOULD NOT BE SHOWN IN SOLNA HUVUDSTA
const HIDDEN_DOOR = '60ddd1bb-751a-4ded-98d2-422dd0e337ff'


// Test location
// 5d065d91-50cc-4e0c-9e1d-09e255539527
// 14849cf5-5807-4491-b9d2-6ef454918818

// Test plate
// OMX785T


/*
const testDevices: Device[] = [
  {
    id: 1,
    name: 'Besöksentré',
    gateDescription: 'Bergrummet',
    doorId: '1',
  },
  {
    id: 2,
    name: 'Besöksentré 2A',
    gateDescription: 'Bergrummet',
    doorId: '1',
  },
]

 */
export const fetchAccessDevices = async (locations: string[], clientId: string): Promise<Device[]> => {
  // const locs = Array.from(new Set(["14849cf5-5807-4491-b9d2-6ef454918818"]))
  const locs = Array.from(new Set(locations))
  try {
    const devices = await Promise.all(locs.map(l =>
      api.access.fetchDevices(clientId, l).then(devices =>
        devices.filter(d => d.doorId !== HIDDEN_DOOR).map(d => d.withLocation(l)))
    ))
    const flattened = [].concat.apply([], devices)
    console.log('devices:', flattened)

    return flattened

  } catch (err) {
    console.error('fetch access devices error', err)
  }
}



export const RemoteAccess: React.FC<Props> = (props) => {

  const [ translate, ] = useTranslation()
  const [ open, setOpen ] = useState(false)
  const [ working, setWorking ] = useState(false)
  const [ result, setResult ] = useState<{ success: boolean } | null>(null)
  const state = useAppState()

  const trigger = async (device: Device) => {
    setWorking(true)
    try {
      const res = await api.access
        .triggerDeviceFn(state.clientId, device.location, device)
      setWorking(false)
      console.log(res)
      setResult(res)
    } catch (err) {
      console.log(err)
      setWorking(false)
      setResult({ success: false })
    }

    setTimeout(() => {
      setResult(null)
    }, 5000)
  }

  useEffect(() => {
    if (props.devices.length == 1 && props.autoTrigger) {
      console.log('auto triggered', props.devices[0]);
      setOpen(true)
      trigger(props.devices[0])
    }
  }, [props.autoTrigger]);

  const content = (): JSX.Element => {
    if (working) {
      return (
        <Modal open={open}
          onClose={() => { setOpen(false) }}
          title={translate('access.opening')}>
          <div className='spinner' />
        </Modal>
      )
    } else if (result) {
      return (
        <Modal open={open}
          onClose={() => { setOpen(false) }}
          title=''>
          {
            result.success && (
              <div className='access__result'>
                <div className='success-symbol' />
                <p>{translate('access.success')}</p>
                <button type='button'
                  onClick={() => setOpen(!open)}
                  className='button'>
                  Ok
                </button>

              </div>
            )
          }
          {
            !result.success && (
              <div className='access__result'>
                <div className='error-symbol' />
                <p>{translate('access.failure')}</p>
                <button type='button'
                  onClick={() => setOpen(!open)}
                  className='button'>
                  Ok
                </button>
              </div>
            )
          }
        </Modal>
      )
    } else {
      return (
        <Modal open={open}
          onClose={() => { setOpen(false) }}
          title={translate('access.title')}
        >
          <Form devices={props.devices}
            onSubmit={(d) => {
              trigger(d)
            }}
          />
        </Modal>
      )
    }

  }

  if (!props.devices || !props.devices.length) return null

  return (
    <div className='access'>
      { content() }
      <button type='button'
        onClick={() => setOpen(!open)}
        className='button'>
        { translate('access.showDoors') }
      </button>
    </div>
  )
}
