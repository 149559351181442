import React from 'react'
import { LicensePlateProps as Props } from './types'
import { NumberPlate } from '../icon'
import { useHistory } from 'react-router-dom'
import { useAppState, useAppDispatch, getSessions } from '../app-state'
import { formattedPlate } from '../../utils'

const LicensePlate: React.FC<Props> = (props) => {

  const state = useAppState()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const navPath = await getSessions(
      state.licensePlate,
      dispatch,
      state.clientId
    )

    if (navPath) {
      history.push(navPath)
    }
  }

  return (
    <form className='form form--reg'
      autoComplete='off'
      onSubmit={handleSubmit}>
      <div className='input-wrapper'>
        <label htmlFor='licensePlate'>
          { props.title }
        </label>
        <NumberPlate style={{
          width: '24px',
          top: '25px',
          transform: 'translateY(-1px)'
        }} />
        <input
          type='text'
          placeholder='XXX YYY'
          name='licensePlate'
          value={state.licensePlate}
          onChange={(e) => {
            dispatch({
              type: 'set_plate',
              payload: formattedPlate(state.licensePlate)(e.target.value)
            })
          }}
        />
      </div>
      <button type='submit'
        disabled={state.loading || !state.licensePlate}
        className='button'>
        { props.action }
      </button>
    </form>
  )
}

export default LicensePlate
