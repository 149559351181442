import React from 'react'
import { motion } from 'framer-motion'


const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

const transition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
}

export const AnimatedPage: React.FC = ({ children }) => {
  return (
    <motion.div
      initial='enter'
      animate='center'
      exit='exit'
      variants={variants}
      transition={transition}
      >
      { children }
    </motion.div>
  )
}

