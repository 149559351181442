import { Session } from '../models';

export interface Total {
  sum: number
  fractions: number
}

export const calculateTotal = (sessions: Session[]): Total => {
  const total = sessions.reduce((a, b) =>
    a + b.priceValue, 0
  )

  return {
    sum: Math.round(total / 100),
    fractions: Math.round(total)
  }
}
