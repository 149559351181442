export class APIError {
  title: string
  detail: string | null

  constructor (data: any) {
    this.title = data.title
    this.detail = data.detail
  }

  get description (): string {
    if (this.detail) {
      return `${this.title}: ${this.detail}`
    }
    return this.title
  }
}
