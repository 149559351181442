import { ClientService, Cache } from './types'
import apiClient from './api-client'
import { Client } from '../models'

const cache: Cache<Client> = {
  key: 'pnp-client-info',

  setData: function (data: Client) {
    window.localStorage.setItem(this.key, JSON.stringify(data))
  },

  clear: function () {
    window.localStorage.removeItem(this.key)
  },

  getData: function (): Client | undefined {
    const json = window.localStorage.getItem(this.key)
    if (json !== null)
      return Client.fromResponse(JSON.parse(json))
  }
}

const service: ClientService = {

  getCachedClient: (): Client | undefined => {
    return cache.getData()
  },

  getClient: async (id: string) => {
    const res = await apiClient.get(`/clients/${id}`)
    try {
      const client = Client.fromResponse(res.data)
      cache.setData(client)
      return client
    } catch (err) {
      throw err
    }
  }

}

export default service
