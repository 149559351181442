import { SessionService } from './types'
import apiClient from './api-client'
import { ClientId, Session } from '../models'

const service: SessionService = {

  getSessions: async (clientId: ClientId, plate: string) => {
    const res = await apiClient
      .get(`/clients/${clientId}/parking_sessions/${plate.replace(' ', '')}`)

    try {
      return res.data.map(Session.fromResponse)
    } catch (err) {
      throw err
    }
  },

}

export default service
