import { FeatureFlag } from "./types"

export type ClientId = string

class Client {

  private _id: ClientId
  private _logo: string
  private _email: string
  private _phone: string
  private _address: string
  private _features: Array<FeatureFlag>
  private _name: string
  private _organisationNumber: string
  private _termsUrl: string

  constructor(data: any) {
    this._id = data.id
    this._logo = data.logo
    this._email = data.email
    this._phone = data.phone
    this._address = data.address
    this._features = data.vat ? ["payments"] : [];
    this._name = data.name
    this._organisationNumber = data.organisationNumber
    this._termsUrl = data.termsUrl
  }

  static fromResponse(data: any): Client {
    return new Client(data)
  }

  get id(): string {
    return this._id
  }

  get logo(): string {
    return this._logo
  }

  get email(): string {
    return this._email
  }

  get phone(): string {
    return this._phone
  }

  get address(): string {
    return this._address
  }

  get name(): string {
    return this._name
  }

  get organisationNumber(): string {
    return this._organisationNumber
  }

  get termsUrl(): string {
    return this._termsUrl
  }


  get hasPaymentsFeature(): boolean {
    return this._features.includes("payments");
  }


}

export default Client
