export class Device {
  id: number
  doorId: string
  description: string
  location: string

  constructor (
    id: number,
    doorId: string,
    description: string,
  ) {
    this.id = id
    this.doorId = doorId
    this.description = description
  }

  withLocation(id: string): Device {
    const d = new Device(
      this.id,
      this.doorId,
      this.description,
    )
    d.location = id
    return d
  }

  static fromResponse(data: any): Device {
    return new Device(
      data.id,
      data.doorId,
      data.description,
    )
  }
}
