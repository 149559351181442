import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { AppStateProvider } from './components/app-state'
import { I18nextProvider } from 'react-i18next'

import i18n from './locale'
import Routes from './components/routing'

import './styles/main.scss'

interface Props {
  clientId: string
}

const App: React.FC<Props> = (props) => {
  return (
    <I18nextProvider i18n={i18n}>
      <AppStateProvider clientId={props.clientId}>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes />
        </Suspense>
      </AppStateProvider>
    </I18nextProvider>
  )
}
const rootElement = document.getElementById('root')
const isStagingUrl = document.location.host.includes('.staging.pnp-client');
const clientId = isStagingUrl
                    ? document.location.host.split('.')[0]
                    : rootElement.dataset.clientId;

render(<App clientId={clientId} />, rootElement)
