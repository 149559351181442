import React from 'react'
import { State, Dispatch } from './types'

export const StateContext = React.createContext<State | undefined>(undefined)
export const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

export const useAppState = (): State => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

export const useAppDispatch = (): Dispatch => {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}
