// We only need the uuid
class ParkingBill {

  private _id: string

  constructor (data: any) {
    this._id = data.id
  }

  static fromResponse (data: any): ParkingBill {
      return new ParkingBill(data)
  }

  get id (): string {
    return this._id
  }

}

export default ParkingBill
