import { ClientId } from "./client";
import moment, { Moment } from 'moment'


class ActivitySummary {
    private _clientId: ClientId;
    private _licensePlate: string;
    private _locationIds: string[];
    private _zoneIds: string[];
    private _lastActive: Moment | null;

    constructor(data: any) {
        this._clientId = data.client_id;
        this._licensePlate = data.license_plate;
        this._locationIds = data.location_ids || [];
        this._zoneIds = data.zone_ids || [];
        this._lastActive = moment(data._lastActive) || null;
    }

    static fromResponse(data: any): ActivitySummary {
        return new ActivitySummary(data);
    }

    static fromNothing(clientId: ClientId, licensePlate: string): ActivitySummary {
        const data = {
            client_id: clientId,
            license_plate: licensePlate
        };
        return new ActivitySummary(data);
    }

    get locationIds(): string[] {
        return this._locationIds;
    }

}

export default ActivitySummary
