import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Clock, LicensePlate } from '../ui'
import { Pay } from '../form'
import { Details, List } from '../session'
import { Support } from '../client'
import { Device } from '../../models'
import { RemoteAccess, fetchAccessDevices } from '../remote-access'
import { useAppState, useAppDispatch, getSessions } from '../app-state'
import { formattedPlate } from '../../utils'

import { AnimatedPage } from './styles'
import NoParking from './no-parking'

const MyParking: React.FC = () => {

  const [ translate ] = useTranslation()
  const state = useAppState()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [ devices, setDevices ] = useState<Device[]>([])
  const [ autoTrigger, setAutoTrigger] = useState<boolean>(false)

  const params = useParams<{ licensePlate: string }>()

  const fetchAccess = async (locations: string[]) => {
    const devices = await fetchAccessDevices(locations, state.clientId)
    setDevices(devices)
    setAutoTrigger(!state.client.hasPaymentsFeature)
  }

  const vehicleIsExpectedInLocation = function (sessions) {
    //TODO: set exitMins from database or don't fetch older sessions
    const exitMins = 15; //grace period after end time the user has to leave
    const minsSinceMostRecentEndTime = 
      Math.min.apply(null, sessions.map(s => s.minutesSinceEnd));
    return minsSinceMostRecentEndTime < exitMins;
  }

  useEffect(() => {
    if (!state.licensePlate.length) {
      dispatch({
        type: 'set_plate',
        payload: formattedPlate(params.licensePlate)(params.licensePlate)
      })
      getSessions(params.licensePlate, dispatch, state.clientId)
    }
  }, [])

  useEffect(() => {
    if (state.activeLocationIds.length || vehicleIsExpectedInLocation(state.sessions)) {


      const ongoing = state.sessions.filter(s => !s.endTime)

      console.log('ongoing', ongoing)
      //      if (ongoing.length > 0) {
      fetchAccess(state.sessions.map(s => s.locationId).concat(state.activeLocationIds)) //we can get rid of the map after migrating from legacy pnp sessions
      //}
    }
  }, [ state.sessions ])

  const {
    sessions,
    licensePlate,
    selected,
    total,
    paymentMethod,
  } = state

  const PaymentPageHeader = function () {
    if (!state.client.hasPaymentsFeature) return null;
    const Title = function () {
      const titleKey = total.sum > 0 ? 'howWillYouPay' : 'nothingToPay';

      return (
        <h1>{ translate(`myParking.${titleKey}`) }</h1>
      );
    }

    return (
      <div>
        <Title/>
        <div className='page--myparking__header'>
          <Clock />
          <LicensePlate text={licensePlate} />
        </div>
      </div>
    );
  };

  const PaymentSection = function () {

    const ongoingSessions = new Set(sessions.filter(s => s.ongoing));
    const needPayment = new Set(sessions.filter(s => s.priceValue > 0));
    const payableSessions = new Set([...ongoingSessions, ...needPayment]);

    if (!payableSessions.size || (payableSessions.size && !state.client.hasPaymentsFeature)) {
      return (
        <div>
          <div className='license-box' style={{padding: 0}}>
            <div className='license-box__plate'>
              {state.licensePlate}
            </div>
          </div>
          <p>{translate('myParking.thanksForVisiting')}</p>
        </div>
      );
    };

    if (!state.client.hasPaymentsFeature) {return null}

    const PaymentOptions = function () {
      if (total.sum <= 0) return null;

      return (
        <Pay
          licensePlate={licensePlate}
          paymentMethod={paymentMethod}
          sessions={[...payableSessions]}
          selected={selected}
          total={total}
        />
      );
    };

    return (
      <div>
        <Details
          sessions={[...payableSessions]}
          selected={selected}
          total={total.sum}
        />
        <List
          sessions={[...payableSessions]}
          selected={selected}
          title={translate('session.listTitle')}
        />
        <PaymentOptions/>
      </div>
    );
  };

  return (
    <AnimatedPage>
      <div className='page'>
        <PaymentPageHeader/>
        <RemoteAccess
          devices={devices}
          autoTrigger={autoTrigger}
        />
        <PaymentSection/>
        <Support
            client={state.client}
            strings={{
              title: translate('support.title'),
              call: translate('support.call'),
              email: translate('support.email')
          }} />

      </div>
    </AnimatedPage>
  )
}

export default MyParking
