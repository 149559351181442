import React from 'react'

const Email: React.FC = () => {
  return (
    <svg
      style={{ transform: 'translateY(-1px)' }}
      width='24px'
      height='16px'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          d='M10.5,0.5 L1.5,0.5 C0.948,0.5 0.5,0.948 0.5,1.5 L0.5,7.5 C0.5,8.052 0.948,8.5 1.5,8.5 L3.5,8.5 L3.5,11.5 L6.5,8.5 L10.5,8.5 C11.052,8.5 11.5,8.052 11.5,7.5 L11.5,1.5 C11.5,0.948 11.052,0.5 10.5,0.5 Z'
          stroke='#333333'
        />
        <path
          d='M6.5,12.5 L9.5,12.5 L12.5,15.5 L12.5,12.5 L14.5,12.5 C15.052,12.5 15.5,12.052 15.5,11.5 L15.5,5.5 C15.5,4.948 15.052,4.5 14.5,4.5 L13.5,4.5'
          stroke='#333333'
        />
      </g>
    </svg>
  )
}

export default Email
