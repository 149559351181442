import React from 'react'
import { StateContext, DispatchContext } from './context'
import reducer, { InitialState } from './reducer'

type Props = {
  clientId: string
}

const Provider: React.FC<Props> = (props) => {
  const [ state, dispatch ] = React.useReducer(reducer, new InitialState(props.clientId))

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        { props.children }
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default Provider
