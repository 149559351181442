import Clock from './clock'
import LicensePlate from './license-plate'
import Phone from './phone'
import Image from './image'
import Email from './email'
import Modal from './modal'

export {
  Clock,
  LicensePlate,
  Image,
  Phone,
  Email,
  Modal,
}
