import React, { useState } from 'react'
import { APIError } from '../../api'

const ErrorBox: React.FC<{ error?: APIError }> = ({ error }) => {
  if (!error) return null

  const [ hidden, setHidden ] = useState<boolean>(false)

  if (hidden) return null

  return (
    <div className='error'>
      <span className='error__exclamation' />
      <p className='error__message'>
        { error.description }
      </p>
      <span onClick={() => setHidden(true)} className='error__close' />
    </div>
  )
}

export default ErrorBox
