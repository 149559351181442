
export async function poll<T>(fn: () => Promise<T>, condition: (value: T) => boolean, ms: number) {
  let result = await fn()
  while (condition(result)) {
    await wait(ms)
    result = await fn()
  }
  return result
}

function wait(ms = 1000) {
  return new Promise(resolve => {
    console.log(`waiting ${ms} ms...`)
    setTimeout(resolve, ms)
  })
}

