import moment, { Moment } from 'moment'
import { i18n } from 'i18next'

export const addDays = (date: Moment, count: number): Moment =>
  moment(date).set('date', date.get('date') + count)

export const addMinutes = (date: Moment, minutes: number): Moment =>
  moment(date).set('minute', date.get('minute') + minutes)

export const isToday = (date: Moment): boolean =>
  moment(date).set('date', date.get('date') - 1).isSame(moment())

export const isYesterday = (date: Moment): boolean =>
  moment(date).set('date', date.get('date') - 1).isSame(moment().set('date', moment().get('date') - 1))

export const format = (date: Moment, i18n: i18n, format?: string): string => {
  let formatted = moment(date).locale(i18n.language).format(format || 'LLL')

  if (isToday(date)) {
    formatted += ` (${ i18n.t('date.today') })`
  }
  if (isYesterday(date)) {
    formatted += ` (${ i18n.t('date.yesterday') })`
  }
  return formatted
}

