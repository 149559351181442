import React, { useState, useEffect } from 'react'
import { ClockState as State } from './types'
import Image from './image'

const Clock: React.FC = () => {

  const [ state, setState ] = useState<State>({
    degSeconds: 0,
    degMinutes: 0,
    degHour: 0
  })

  const update = () => {
    const time = new Date()
    const seconds = time.getSeconds()
    const minutes = time.getMinutes()
    const hours = time.getHours()
    const hours12 = ((hours + 11) % 12) + 1

    const degSeconds = (360 / 60) * seconds
    const degMinutes = (360 / 60) * minutes
    const degHour = 0.5 * (hours12 * 60 + minutes)

    setState({ degHour, degMinutes, degSeconds })
  }

  let clockHandle: number

  useEffect(() => {
    clockHandle = window.setInterval(update, 1000)
    update()

    return () => {
      if (clockHandle !== null)
        clockHandle = null
    }
  }, [])

  const clockHour = {
    transform: `rotate(${state.degHour}deg)`
  }
  const clockMin = {
    transform: `rotate(${state.degMinutes}deg)`
  }
  const clockSeconds = {
    transform: `rotate(${state.degSeconds}deg)`
  }


  return (
    <div className='clock' style={{ marginRight: '20px' }}>
      <Image className='clock__bg'
        src={require('../../assets/img/clock.jpg')}
        src2={require('../../assets/img/clock@2x.jpg')}
        alt='Clock background'
      />

      <svg width='77' height='77' className='clock__hands' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'>
            <stop stopColor='#EAEDEE' offset='0%' />
            <stop stopColor='#ADB9C5' offset='100%' />
          </linearGradient>
          <path
            d='M38.5 7c.556 0 1 .444 1 .993v30.014c0 .54-.448.993-1 .993-.556 0-1-.444-1-.993V7.993c0-.54.448-.993 1-.993zm0 .75a.43.43 0 0 0-.429.428v3.144c0 .236.2.428.429.428a.43.43 0 0 0 .429-.428V8.178a.433.433 0 0 0-.429-.428z'
            id='b'
          />
          <filter
            x='-750%'
            y='-46.9%'
            width='1600%'
            height='193.8%'
            filterUnits='objectBoundingBox'
            id='a'
          >
            <feOffset in='Sourcelpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
              in='shadowBlurOuter1'
            />
          </filter>
          <path
            d='M38.5 11c.834 0 1.5.674 1.5 1.505v24.99a1.499 1.499 0 1 1-3 0v-24.99c0-.84.672-1.505 1.5-1.505zm0 2.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z'
            id='e'
          />
          <filter
            x='-500%'
            y='-53.6%'
            width='1100%'
            height='207.1%'
            filterUnits='objectBoundingBox'
            id='d'
          >
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
              in='shadowBlurOuter1'
            />
          </filter>
          <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='h'>
            <stop stopColor='#EAEDEE' offset='0%' />
            <stop stopColor='#D9E1E9' offset='100%' />
          </linearGradient>
          <path
            d='M29.394 27.033c-.243 1.957-1.805 3.467-3.696 3.467-1.53 0-2.843-.987-3.419-2.4H18.1a1.601 1.601 0 0 1 0-3.2h4.18c.575-1.413 1.889-2.4 3.418-2.4 1.89 0 3.453 1.51 3.696 3.467h30.573c.292 0 .533.238.533.533a.539.539 0 0 1-.533.533H29.394z'
            id='g'
          />
          <filter
            x='-36.4%'
            y='-100%'
            width='171.5%'
            height='500%'
            filterUnits='objectBoundingBox'
            id='f'
          >
            <feOffset dy='8' in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='4' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
              in='shadowBlurOuter1'
            />
          </filter>
          <filter
            x='-21.6%'
            y='-18.8%'
            width='142%'
            height='337.5%'
            filterUnits='objectBoundingBox'
            id='i'
          >
            <feGaussianBlur stdDeviation='1' in='SourceAlpha' result='shadowBlurInner1' />
            <feOffset dy='1' in='shadowBlurInner1' result='shadowOffsetInner1' />
            <feComposite
              in='shadowOffsetInner1'
              in2='SourceAlpha'
              operator='arithmetic'
              k2='-1'
              k3='1'
              result='shadowInnerInner1'
            />
            <feColorMatrix
              values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
              in='shadowInnerInner1'
            />
          </filter>
        </defs>
        <g className='clock__group' fill='none' fillRule='evenodd'>
          <g>
            <g className='clock__min' style={clockMin}>
              <use fill='#000' filter='url(#a)' xlinkHref='#b' />
              <use fill='url(#c)' xlinkHref='#b' />
            </g>
            <g className='clock__hour' style={clockHour}>
              <use fill='#000' filter='url(#d)' xlinkHref='#e' />
              <use fill='url(#c)' xlinkHref='#e' />
            </g>
            <g className='clock__sec' style={clockSeconds}>
              <g transform='rotate(-90 38.5 26.5)'>
                <use fill='#000' filter='url(#f)' xlinkHref='#g' />
                <use fill='url(#h)' xlinkHref='#g' />
                <use fill='#000' filter='url(#i)' xlinkHref='#g' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Clock
