import React, { useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { createBrowserHistory } from 'history'
import { Home, MyParking, NoParking, Payment, PaymentResult } from '../page'
import Header from '../header'
import ErrorBox from '../error'
import Footer from '../footer'
import { EmailForm } from '../form'

import {
  useAppState,
  useAppDispatch,
  fetchClient
} from '../app-state'

const history = createBrowserHistory()

const Routes: React.FC = () => {

  const state = useAppState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchClient(dispatch, state.clientId)
  }, [])


  const match = window.location.href.match(/(success|failure)/)
  const headerVisible = match === null || match.length === 0


  return (
    <Router history={history}>
      <Header client={state.client} visible={headerVisible} />
      <AnimatePresence>
        <Switch>
          <Route exact path='/result/:result'
            component={PaymentResult}
          />
          <Route path='/my-parking/:licensePlate/payment/:type'
            component={Payment}
          />
          <Route exact path='/my-parking/:licensePlate'
            component={MyParking}
          />
          <Route path='/no-parking/:licensePlate'
            component={NoParking}
          />
          <Route
            component={Home}
          />
        </Switch>
      </AnimatePresence>
      <ErrorBox error={state.error} />
      <Footer client={state.client} />
    </Router>
  )
}

export default Routes
