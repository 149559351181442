import React from 'react'
import { useSpring, animated } from 'react-spring'
import { createPortal } from 'react-dom'
import { ModalProps as Props } from './types'


const Modal: React.FC<Props> = (props) => {

  const close = () => {
    props.onClose && props.onClose()
  }


  if (!props.open) return null

  const styles = useSpring({
    opacity: 1,
    transform: 'scale(1.0) translate(-50%, -50%)',
    from: { opacity: 0, transform: 'scale(0.8) translate(-50%, -50%)' }
  })

  return createPortal(
    (
      <div className='modal'>
        <div className='modal__overlay' />
        <animated.div style={styles} className='modal__box'>
          { props.title &&
            <div className='modal__header'>
              <h1 className='modal__title'>{props.title}</h1>
              <button
                onClick={() => { close() }}
                className='modal__close'>
                <span>&#215;</span>
              </button>
            </div>
          }
          <div className='modal__body'>
            {props.children}
          </div>
        </animated.div>
      </div>
    ),
    document.body
  )
}

export default Modal
