import { Dispatch } from './types'
import api from '../../api'
import { SwishBody, StripeBody } from '../../types'
import config from '../../config'
import { APIError } from '../../api'
import { SessionId, ParkingBill } from '../../models'

export const fetchClient = async (dispatch: Dispatch, clientId: string) => {
  dispatch({ type: 'loading' })
  try {
    const client = await api.client.getClient(clientId)
    dispatch({
      type: 'get_client',
      payload: client,
    })
  } catch (err) {
    dispatch({
      type: 'get_client_error',
      payload: err
    })
  }
}

type NavigationPath = string

export const getSessions = async (
  licensePlate: string,
  dispatch: Dispatch,
  clientId: string,
): Promise<NavigationPath> => {

  dispatch({ type: 'loading' })

  try {
    const sessions = await api.session.getSessions(clientId, licensePlate);
    const activity = await api.activity.getActivity(clientId, licensePlate, 15);

    dispatch({
      type: 'get_sessions',
      payload: {
        sessions: sessions, locationIds: activity.locationIds
      }
    })

    let plate = licensePlate.replace(' ', '')

    if (sessions.length || activity.locationIds.length) {
      return `/my-parking/${plate}`
    } else {
      return `/no-parking/${plate}`
    }

  } catch (err) {
    dispatch({
      type: 'get_sessions_error',
      payload: err
    })

    return `/no-parking/${licensePlate}`
  }
}

export const initiateSwish = async (
  licensePlate: string,
  sessions: SessionId[],
  body: SwishBody,
  dispatch: Dispatch,
  clientId: string,
) => {
  dispatch({ type: 'loading' })

  if (body.phoneNr.length === 0) {
    let payload = new APIError({ title: 'Missing phone number' })
    dispatch({ type: 'get_swish_result_error', payload })
    return
  }

  let bill: ParkingBill

  try {
    bill = await api.payment.createParkingBill(clientId, {
      licensePlate, sessions
    })

    dispatch({ type: 'create_parking_bill', payload: bill })
    body.parkingBillId = bill.id
  } catch (err) {
    dispatch({ type: 'create_parking_bill_error', payload: err })
    return
  }


  try {
    const result = await api.payment.initiateSwishPayment(clientId, body)
    dispatch({ type: 'get_swish_result', payload: result })
    return bill.id
  } catch (err) {
    dispatch({ type: 'get_swish_result_error', payload: err })
  }
}

export const initiateStripe = async (
  licensePlate: string,
  sessions: SessionId[],
  body: StripeBody,
  dispatch: Dispatch,
  clientId: string,
) => {
  dispatch({ type: 'loading' })
  let bill: ParkingBill

  try {
    bill = await api.payment.createParkingBill(clientId, {
      licensePlate, sessions
    })
    dispatch({ type: 'create_parking_bill', payload: bill })
    body.parkingBillId = bill.id
  } catch (err) {
    dispatch({ type: 'create_parking_bill_error', payload: err })
    return
  }

  try {

    const result = await api.payment.initiateStripePayment(clientId, body)
    dispatch({ type: 'get_stripe_result', payload: result })

  } catch (err) {
    dispatch({ type: 'get_stripe_result_error', payload: err })
  }
}

export const sendReceipt = async (email: string, billId: string, dispatch: Dispatch, clientId: string) => {
  dispatch({ type: 'loading' })
  try {
    await api.payment.sendReceipt(clientId, billId, email)
    dispatch({ type: 'set_email_complete' })
  } catch (err) {
    dispatch({ type: 'set_email_error', payload: err })
  }
}
