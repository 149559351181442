import { Config } from './types'

const { NODE_ENV } = process.env

const isProduction = NODE_ENV === 'production'

const config: Config = {
  production: isProduction,
  development: !isProduction,

  serverUrl: function () {
    if (this.production) {
        return 'https://pnp-server.mobility46.se/api/v1'
    }

    return 'https://pnp-server-stage.mobility46.se/api/v1'
    // return 'http://localhost:8080/api/v1'
  },
}

console.log('config:', config)

export default config
