import React, { useEffect, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useAppState, useAppDispatch, initiateSwish } from '../app-state'
import { Phone } from '../ui'
import { formattedPhoneNumber } from '../../utils'
import EmailForm from './email'

const Swish: React.FC = () => {

  const [ translate ] = useTranslation()
  const state = useAppState()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParams<{ licensePlate: string }>()

  const [bill, setBill] = useState<string | undefined>()

  useEffect(() => {
    if (state.sessions.length === 0) {
      history.push(`/my-parking/${params.licensePlate}`)
    }
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const optBill = await initiateSwish(
      state.licensePlate.replace(' ', ''),
      state.selected,
      {
        expectedPrice: state.total.fractions,
        phoneNr: state.phoneNumber,
        parkingBillId: state.billId,
      },
      dispatch,
      state.clientId,
    )


    if (optBill) {
      console.log('got bill with id:', optBill)
      setBill(optBill)
    }
  }

  if (state.swishResult) {
    return (
      <div>
        <h1>{ translate(`payment.${state.swishResult}`) }</h1>
        { bill && state.swishResult !== 'error' && <EmailForm bill={bill} /> }
      </div>
    )
  }

  return (
    <>
      <form className='form--pay-card'
        onSubmit={handleSubmit}>
        {
          state.loading
            ? (
              <div>
                <div className='spinner' />
                <p className="text text--standard">{translate('swish.open.title')}</p>
              </div>
            ) : (
              <Fragment>
                <h1>
                  { translate('swish.title') }
                </h1>
                <div className='input-wrapper'>
                  <label htmlFor='phone'>
                    { translate('swish.tel') }
                  </label>
                  <Phone />
                  <input
                    id='phone'
                    value={state.phoneNumber}
                    onChange={({ target: { value } }) => {
                      dispatch({
                        type: 'set_phone_number',
                        payload: formattedPhoneNumber(value)
                      })
                    }}
                    type='tel'
                    name='phone'
                    placeholder='07X XXX XX XX'
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <button className='button'
                    type='submit'>
                    { translate('swish.action', { amount: state.total.sum }) }
                  </button>
                </div>
              </Fragment>
            )
        }
      </form>
    </>
  )
}

export default Swish
