import { API } from './types'
import activity from './activity-summary'
import session from './session'
import client from './client'
import payment from './payment'
import access from './remote-access'

export { APIError } from './error'

const api: API = {
  activity,
  session,
  client,
  payment,
  access,
}

export default api
