import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemProps, ListProps } from './types'
import { useAppDispatch } from '../app-state'
import ReactDom from 'react-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Item: React.FC<ItemProps> = ({ session, selected, disabled }) => {

  const [translate,] = useTranslation()
  const dispatch = useAppDispatch()
  const [priceInfoVisible, setPriceInfoVisible] = useState<boolean>(false);

  function togglePriceInfo() {
    setPriceInfoVisible(priceInfoVisible => !priceInfoVisible);
  }

  console.log(session.ongoing)

  return (
    <div className='parkingbox'>
      <div className='parkingbox__info'>
        <div className='parkingbox__select'>
          <div className='checkbox-btn'>
            <input
              id={session.id}
              onChange={() => {
                dispatch({ type: 'select_session', payload: session.id })
              }}
              checked={selected}
              disabled={disabled}
              className='parkingbox__input'
              type='checkbox'
            />
            <label htmlFor={session.id}>
              <div>
                <div className='parkingbox__details'>
                  <p>
                    <span>{translate('session.checkin')}</span>
                    <span>
                      {session.startTime}
                    </span>
                  </p>
                  <p>
                    <span>{translate('session.checkout')}</span>
                    <span>
                      <span className={session.ongoing ? 'highlighted' : ''}>
                        {session.ongoing ? translate('session.ongoing') : session.endTime}
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>{translate('session.location')}</span>
                    <span>
                      {session.location} - {session.zone}
                    </span>
                  </p>

                  <p>
                    <span>
                      {translate('session.price')}
                    </span>
                    <span className='bold'>
                      {session.price}
                    </span>
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className='parkingbox__priceinfolink'>
        <a onClick={togglePriceInfo}> {translate('session.pricingInformation')} </a>
      </div>
      {priceInfoVisible &&
        <div className='parkingbox__priceinfo'>
          <ReactMarkdown children={session.pricingDescription} remarkPlugins={[remarkGfm]} />
        </div>
      }
    </div>
  )
}

const List: React.FC<ListProps> = (props) => {
  return (
    <React.Fragment>
      <h2>
        {props.title}
      </h2>
      <div>
        {
          props.sessions.map(s => (
            <Item
              key={s.id}
              session={s}
              selected={s.priceValue > 0 && props.selected.some(id => id === s.id)}
              disabled={(s.priceValue <= 0)}
            />
          ))
        }
      </div>
    </React.Fragment>
  )
}

export default List












