import axios from 'axios'
import config from '../config'
import { APIError } from './error'


const client = axios.create({
  baseURL: config.serverUrl(),
  headers: {
    'content-type': 'application/json'
  }
})

client.interceptors.response.use(
  res => res,
  err => {
    if (err.response) {
      const { data } = err.response
      return Promise.reject(new APIError(data))
    } else {
      return Promise.reject(new APIError({ title: 'Unknown error' }))
    }
  }
)

export default client
