import LicensePlate from './license-plate'
import Pay from './pay'
import Swish from './swish'
import Stripe from './stripe'
import Email from './email'

export {
  LicensePlate,
  Pay,
  Swish as SwishForm,
  Stripe as StripeForm,
  Email as EmailForm,
}
