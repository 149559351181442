import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EmailForm from '../form/email'

const useQuery = () => new URLSearchParams(useLocation().search)

const PaymentResult: React.FC = () => {

  const [ translate ] = useTranslation()
  const params = useParams<{ result: 'success' | 'failure' }>()
  const query = useQuery()

  const bill = query.get('bill')

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        { translate(`payment.${params.result}`) }
      </h3>
      { params.result === 'success' && bill && <EmailForm bill={bill} /> }
    </div>
  )

}

export default PaymentResult
