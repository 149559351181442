import React from 'react'
import { AnimatedPage } from './styles'
import { useTranslation } from 'react-i18next'
import { LicensePlate } from '../form'

const Home: React.FC = () => {

  const [ translate ] = useTranslation()

  return (
    <AnimatedPage>
      <div className='page'>
        <h1>{ translate('home.title') }</h1>
        {/*
        <div className='page__graphic'>
          <img src={require('../../assets/img/start-graphics@2x.png')} />
        </div>
        */}
        <LicensePlate
          title={translate('home.form.licensePlate.title')}
          action={translate('home.form.licensePlate.action')}
        />
      </div>
    </AnimatedPage>
  )
}

export default Home
