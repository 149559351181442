import React from 'react'
import { Props } from './types'
import { useTranslation } from 'react-i18next'



const Footer: React.FC<Props> = ({ client }) => {

    if (!client || !client.hasPaymentsFeature) return null;

    const [translate,] = useTranslation()


    return (
        <div className="page" style={{ paddingBottom: 0 }}>
            <footer>
                <ul className="footerItems">
                    <li>{client.name}&nbsp;&middot;&nbsp;{client.organisationNumber}</li>
                    <li>{client.address}</li>
                    <li><a href={client.termsUrl} target="_blank">{translate('footer.terms')}</a></li>
                </ul>
            </footer>
        </div >
    )
}

export default Footer
