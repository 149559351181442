import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Device } from '../../models'
import { FormProps as Props } from './types'


export const Form: React.FC<Props> = (props) => {

  const [ translate, ] = useTranslation()
  const [ selected, setSelected ] = useState<Device | null>(null)

  useEffect(() => {
    if (props.devices.length === 1) {
      setSelected(props.devices[0])
    }
  }, [ props.devices ])

  return (
    <form className='form--access'
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit(selected)
      }}>
      <ul className='access-list'>
        {
          props.devices.map((d) => {
            return (
              <li
                className='access-list__item' key={d.doorId}>
                <label className='radio' htmlFor={d.description}>
                  <span className='radio__input'>
                    <input
                      onChange={() => {
                        setSelected(d)
                      }}
                      type='radio'
                      id={d.description}
                      checked={d.doorId === selected?.doorId}
                    />
                    <span className='radio__control' />
                  </span>
                  <span className='radio__label'>
                    {d.description}
                  </span>
                </label>
              </li>
            )
          })
        }
      </ul>
      <button className='button'
        disabled={!selected}
        type='submit'
      >
       { translate('access.open') }
      </button>
    </form>
  )
}
