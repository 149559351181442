import React from 'react'
import { Props } from './types'

const NumberPlate: React.SFC<Props> = ({ style }) => (
  <svg
    style={style}
    className='icon__regid'
    width='16px'
    height='16px'
    viewBox='0 0 16 16'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' fill='none' fillRule='evenodd'>
      <rect stroke='#333333' x='0.5' y='0.5' width='15' height='15' rx='2' />
      <circle fill='#333333' cx='3' cy='3' r='1' />
      <circle fill='#333333' cx='13' cy='3' r='1' />
      <circle fill='#333333' cx='3' cy='13' r='1' />
      <circle fill='#333333' cx='13' cy='13' r='1' />
    </g>
  </svg>
)

export default NumberPlate
