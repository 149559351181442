import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Email as IconEmail } from '../ui'
import { useAppState, useAppDispatch, sendReceipt } from '../app-state'
import { Modal } from '../ui'

interface Props {
  bill: string
}

const Email = (props: Props) => {

  const [ open, setOpen ] = useState(true)

  const [ translate, ] = useTranslation()
  const state = useAppState()
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    console.log('sendReceipt', state.email, props.bill)
    sendReceipt(state.email.trim(), props.bill, dispatch, state.clientId)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}
      title={translate('email.title')}>
      <form className='form form--email'
        autoComplete='off'>
        <div className='input-wrapper'>
          <label htmlFor='email'>
            { translate('email.label') }
          </label>
          <IconEmail />
          <input
            type='text'
            onChange={({ target: { value } }) => dispatch({
              type: 'set_email',
              payload: value
            })}
            placeholder={ translate('email.placeholder') }
            name='email'
            value={state.email}
          />
        </div>
        <button type='button'
          onClick={handleSubmit}
          disabled={!state.email || state.email.length < 5}
          className='button'>
          { translate('email.action') }
        </button>
          <p className='success'>
            {
              state.receiptSent ? translate('email.sent') : ''
            }
          </p>
      </form>
    </Modal>
  )
}

export default Email
