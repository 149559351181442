import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FlagEn, FlagSv } from '../icon'
import { Props } from './types'

const Header: React.FC<Props> = ({ client, visible }) => {

  const [ , i18n ] = useTranslation()

  const logo = client ? client.logo : '/images/logo.png'

  if (!visible) return null

  return (
    <header className='header'>
      <div className='header__content'>
        <div className='header__column'>
          <button onClick={() => {
            const lang = i18n.language === 'sv' ? 'en' : 'sv'
            i18n.changeLanguage(lang)
            moment.locale(lang)
          }}
          className='button--icon'>
            <div className='svg-background'>
              { i18n.language === 'en'
                ? <FlagEn />
                : <FlagSv />
              }
            </div>
          </button>
        </div>
        <div className='header__column'>
          <Link to='/' className='link link__back'>
            <img className='header__image'
              src={logo || '/images/logo.png'} />
          </Link>
        </div>
        <div className='header__column' />
      </div>
    </header>
  )
}

export default Header
