import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useAppState, useAppDispatch, initiateStripe } from '../app-state'


const Stripe: React.FC = () => {

  const [ translate ] = useTranslation()
  const state = useAppState()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParams<{ licensePlate: string }>()

  useEffect(() => {
    if (state.sessions.length === 0) {
      history.push(`/my-parking/${params.licensePlate}`)
    } else {

      initiateStripe(
        state.licensePlate.replace(' ', ''),
        state.selected,
        {
          expectedPrice: state.total.fractions,
          redirectUrl: `${window.location.origin}/result/`,
        },
        dispatch,
        state.clientId
      )
    }
  }, [])


  return (
    <div className='form--pay-card'>
      <h1>
        { translate('stripe.title') }
      </h1>
      { state.loading ? <div className='spinner' /> : null }
      <iframe className='stripe-frame'
        src={state.stripeUrl}
      />
    </div>
  )
}

export default Stripe
