import React from 'react'
import { Props } from './types'

export const ArrowBack: React.FC<Props> = ({ style }) => (
  <svg
    style={style}
    width='20px'
    height='14px'
    viewBox='0 0 20 14'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      transform='translate(1, 1)'
      stroke='#8795A8'
      strokeWidth='2'
      fill='none'
      opacity='0.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M18,5.91044776 L0,5.91044776 L4.74626866,11.8208955 M0,5.91044776 L4.71044776,0' />
    </g>
  </svg>
)
