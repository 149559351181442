import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Image } from '../ui'
import { PayProps } from './types'
import { useAppDispatch } from '../app-state'

const Pay: React.FC<PayProps> = (props) => {

  const [ translate, ] = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    history.push(
      `/my-parking/${props.licensePlate}/payment/${props.paymentMethod}`
    )
  }

  return (
    <form
      className='form--pay'
      onSubmit={handleSubmit}
    >
      <h2>
        { translate('payment.title') }
      </h2>
      <div className='form__options'>
        <div className='radio-btn'>
          <input
            id='swish'
            type='radio'
            checked={props.paymentMethod === 'swish'}
            onChange={() => dispatch({
              type: 'set_payment_method',
              payload: 'swish'
            })}
          />
          <label htmlFor='swish' className='label--image'>
            <Image
              src={require('../../assets/img/swish.png')}
              src2={require('../../assets/img/swish@2x.png')}
              alt='swish'
            />
          </label>
        </div>
        <div className='radio-btn'>
          <input
            id='stripe'
            type='radio'
            checked={props.paymentMethod === 'stripe'}
            onChange={() => dispatch({
              type: 'set_payment_method',
              payload: 'stripe'
            })}
          />
          <label htmlFor='stripe' className='label--image'>
            <Image src={require('../../assets/img/creditcards.png')}
              src2={require('../../assets/img/creditcards@2x.png')}
              alt='stripe'
            />
          </label>
        </div>
      </div>
      <div>
      <button className='button'
        disabled={props.total.sum === 0 || props.paymentMethod === undefined}
        type='submit'
      >
        { translate('payment.action', { amount: props.total.sum || 0 }) }
      </button>
      </div>
    </form>

  )
}

export default Pay
