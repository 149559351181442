import NumberPlate from './number-plate'
import { FlagSv, FlagEn } from './flag'
import { ArrowBack } from './arrow'

export {
  NumberPlate,
  FlagEn,
  FlagSv,
  ArrowBack,
}
