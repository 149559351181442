import moment, { Moment } from 'moment'

export type SessionId = string

interface Zone {
  id: string
  name: string
  locationName: string
  locationId: string
}

class Session {

  private _id: SessionId
  private _zone: Zone
  private _startTime: Moment
  private _endTime?: Moment
  private _priceValidUntil: Moment
  private _price: number
  private _formattedPrice: string
  private _pricingDescription: string

  constructor(data: any) {
    this._id = data.id
    this._zone = {
      id: data.zoneId,
      name: data.zoneName,
      locationName: data.locationName,
      locationId: data.locationId,
    }
    this._startTime = moment(data.startTime)
    this._endTime = data.endTime ?
      moment(data.endTime) :
      undefined
    this._priceValidUntil = moment(data.priceValidUntil)
    this._price = data.price
    this._formattedPrice = data.formattedPrice
    this._pricingDescription = data.pricingDescription || 'See signs/Se skyltar'; //default to make deploy easier
  }

  static fromResponse(data: any): Session {
    return new Session(data)
  }

  get id(): string {
    return this._id
  }

  get price(): string {
    return this._formattedPrice
  }

  get priceValue(): number {
    return this._price
  }

  get pricingDescription(): string {
    return this._pricingDescription
  }

  get startTime(): string {
    return this._startTime.format('lll')
  }

  get endTime(): string | undefined {
    return this._endTime ?
      this._endTime.format('lll') :
      undefined
  }

  get location(): string {
    return this._zone.locationName
  }

  get locationId(): string {
    return this._zone.locationId
  }

  get zone(): string {
    return this._zone.name
  }

  get ongoing(): boolean {
    return this._endTime === undefined
  }

  get validUntil(): string {
    return this._priceValidUntil.format('lll')
  }

  get minutesSinceEnd(): number {
    return this._endTime ? moment().diff(this._endTime, 'minutes') : 0
  }
}

export default Session
