import Home from './home'
import MyParking from './my-parking'
import NoParking from './no-parking'
import Payment from './payment'
import PaymentResult from './payment-result'

export {
  Home,
  MyParking,
  NoParking,
  Payment,
  PaymentResult,
}
