import { ActivityService } from './types'
import { ClientId, ActivitySummary } from '../models'
import apiClient from './api-client'

const service: ActivityService = {

    getActivity: async (clientId: ClientId, plate: string, maxAgeMinutes: number): Promise<ActivitySummary> => {
        const url = `/clients/${clientId}/parking_sessions/${plate.replace(' ', '')}/activity-summary/`;
        const res = await apiClient.get(url, { params: { max_age_minutes: maxAgeMinutes } });

        if (res.data.length == 1) {
            return ActivitySummary.fromResponse(res.data[0]);
        } else {

            if (res.data.length > 1) {
                console.error('Server responded with more data than expected for activity summary. Reporting 0 activity.');
            }

            return ActivitySummary.fromNothing(clientId, plate);
        }
    }

};

export default service

