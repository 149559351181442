import React from 'react'
import { SupportProps as Props } from './types'

const Support: React.FC<Props> = ({ client = {}, strings }) => (
  <div className='support-box'>
    {
      strings.title && <p>{strings.title}</p>
    }
    <a href={`mailto:${client.email}`} className='button button--inverted'>
      {strings.email}
    </a>
    {
      client.phone && <p>
        {strings.call}
        <a href={`tel:${client.phone}`}>{client.phone}</a>
      </p>
    }
  </div>
)

export default Support
