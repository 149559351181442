import { RemoteAccessService } from './types'
import apiClient from './api-client'
import { Device } from '../models'

const service: RemoteAccessService = {
  fetchDevices: async (clientId: string, locationId: string) => {
    const res = await apiClient
      .get(`/clients/${clientId}/locations/${locationId}/devices`)

    try {
      return res.data.map(Device.fromResponse)
    } catch (err) {
      throw err
    }
  },

  triggerDeviceFn: async (clientId: string, locationId: string, device: Device) => {
    const res = await apiClient
      .get(`/clients/${clientId}/locations/${locationId}/devices/${device.doorId}/trigger/${device.id}`)
    return JSON.parse(res.data)
  }
}

export default service
