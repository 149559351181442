import { Action, State } from './types'
import api from '../../api'
import { calculateTotal } from '../../utils'

export const InitialState = function (clientId: string) {
  this.clientId = clientId || '';
  this.client = api.client.getCachedClient(),
    this.phoneNumber = '';
  this.licensePlate = '';
  this.sessions = [];
  this.selected = [];
  this.activeLocationIds = [];
  this.loading = false;
  this.total = { sum: 0, fractions: 0 };
};

const reducer = (state: State, action: Action): State => {
  console.log('action:', action.type)
  switch (action.type) {

    case 'get_client': {
      return {
        ...state,
        client: action.payload,
        loading: false
      }
    }

    case 'get_client_error': {
      return {
        ...state,
        client: undefined,
        error: action.payload,
        loading: false
      }
    }

    case 'set_plate': {
      return { ...state, licensePlate: action.payload }
    }

    case 'set_phone_number': {
      return { ...state, phoneNumber: action.payload }
    }

    case 'get_sessions': {
      return {
        ...state,
        loading: false,
        sessions: action.payload.sessions,
        selected: action.payload.sessions.map(s => s.id),
        total: calculateTotal(action.payload.sessions),
        activeLocationIds: action.payload.locationIds,
      }
    }

    case 'get_sessions_error': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case 'select_session': {
      const selected = state.selected.find(id => id === action.payload) ?
        state.selected.filter(id => id !== action.payload) :
        [...state.selected, action.payload]

      const total = calculateTotal(
        state.sessions.filter(s => selected.some(id => id === s.id))
      )

      return {
        ...state,
        selected,
        total,
      }
    }

    case 'set_payment_method': {
      return { ...state, paymentMethod: action.payload }
    }

    case 'loading': {
      return { ...state, loading: true }
    }

    case 'create_parking_bill': {
      return { ...state, billId: action.payload.id }
    }

    case 'create_parking_bill_error': {
      return { ...state, error: action.payload, loading: false }
    }

    case 'get_swish_result': {
      return {
        ...state,
        swishResult: action.payload ? action.payload.outcome : undefined,
        loading: false,
      }
    }

    case 'get_swish_result_error': {
      console.log(action)
      return { ...state, error: action.payload, loading: false }
    }

    case 'get_stripe_result': {
      return {
        ...state,
        loading: false,
        stripeUrl: action.payload.paymentUrl,
      }
    }

    case 'get_stripe_result_error': {
      console.log(action)
      return { ...state, error: action.payload, loading: false }
    }

    case 'set_email': {
      return { ...state, email: action.payload }

    }

    case 'set_email_complete': {
      return { ...state, loading: false, receiptSent: true }
    }

    case 'set_email_error': {
      return { ...state, error: action.payload, loading: false }
    }

    default:
      return state
  }
}

export default reducer
