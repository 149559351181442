import {
  AsYouType,
  parsePhoneNumberFromString,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js'

const validPlate = (value: string): string =>
  value.length > 10
    ? value.substring(0, 10)
    : value

export const formattedPlate = (value: string) => {
  let previous = value

  return (newValue?: string) => {
    let val = newValue || value

    console.log(val.length)

    if (newValue.length === 0) {
      val = ''
    } else if (newValue.length > previous.length) {
      if (newValue.length === 3) {
        val = newValue + ' '
      }
    }

    if (newValue.length === 8) {
      val = val.replace(' ', '')
    }

    val = validPlate(val)

    previous = val
    return val.toUpperCase()
  }
}

export const formattedPhoneNumber = (value: string): string => {
  const num = new AsYouType().input(value)

  let parsed = parsePhoneNumberFromString(num)
  if (parsed) {
    return parsed.formatNational()
  } else {
    return parseIncompletePhoneNumber(num)
  }
}
