import {
  addMinutes,
  addDays,
  isToday,
  isYesterday,
  format
} from './date'

import { formattedPlate, formattedPhoneNumber } from './format'
import { calculateTotal } from './price'
import { openSwish } from './swish'

export {
  format,
  addMinutes,
  addDays,
  isToday,
  isYesterday,
  formattedPlate,
  formattedPhoneNumber,
  calculateTotal,
  openSwish,
}
