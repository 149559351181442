import React, { useEffect } from 'react'
import Markdown from 'react-remarkable'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppState, useAppDispatch } from '../app-state'
import { ArrowBack } from '../icon'
import { Support } from '../client'
import { AnimatedPage } from './styles'
import { formattedPlate } from '../../utils'


const NoParking: React.FC = () => {

  const state = useAppState()
  const dispatch = useAppDispatch()
  const params = useParams<{ licensePlate: string }>()

  useEffect(() => {
    if (!state.licensePlate.length) {
      dispatch({
        type: 'set_plate',
        payload: formattedPlate(params.licensePlate)(params.licensePlate)
      })
    }
  }, [])

  function NoParkingReasons () {
    if (!state.client.hasPaymentsFeature) return null;

    return (
      <div className="page--noparking__sub-container">
        <p><Markdown>{ translate('noParking.reasons.title') }</Markdown></p>
        <ul>
          {Array.from(Array(2)).map((_, i) => (
            <li key={i}>
              <Markdown>
                { translate(`noParking.reasons.list.${i}`) }
              </Markdown>
            </li>
          ))}
        </ul>
            
        <p>
          { translate(`noParking.pleaseRetryTomorrow`) }
        </p>
      </div>
    );
  };

  const history = useHistory()
  const [ translate, ] = useTranslation()

  return (
    <AnimatedPage>
      <div className='page page--noparking'>
        <a onClick={history.goBack}>
          <ArrowBack />

        </a>
        <h1>{ translate('noParking.title') }</h1>
        <h2>{ translate('noParking.subtitle') }</h2>
        <div className='license-box'>
          <div className='license-box__plate'>
            {state.licensePlate}
          </div>
        </div>
        <div className="attention">{translate('noParking.attention')}</div>
        <button className='button'
          onClick={history.goBack}>
          { translate('noParking.button') }
        </button>
          <NoParkingReasons />
          <Support
            client={state.client}
            strings={{
              title: translate('noParking.supportTitle'),
              call: translate('support.call'),
              email: translate('support.email')
            }} />
      </div>
    </AnimatedPage>

  )
}

export default NoParking
