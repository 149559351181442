import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailProps } from './types'

const Details: React.FC<DetailProps> = ({ sessions, total }) => {

  const [ translate, ] = useTranslation()

  if (!sessions.length || total <= 0) return null

  return (
    <div className='details-wrapper'>
      <h1>
        <span>{ translate('session.toPay') }: </span>
        <span className='price bold'>{ total }kr</span>
      </h1>
    </div>
  )
}

export default Details
